<!--
 * @Description: 登录
 * @Autor: zhangzhang
 * @Date: 2021-08-18 11:19:34
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-02-25 09:47:25
-->
<template>
  <div class="login">
    <div class="login-box">
      <div class="left">
        <img src="../assets/image/login-box.png" />
      </div>
      <div class="right">
        <div class="logo">
          <img src="../assets/image/zhibaiTitle.png" />
        </div>
        <div class="hope">
          <span>智摆企业官网建站系统 | 支持全行业DIY官网</span>
        </div>
        <div class="login_title">
          <ul class="flex col-bottom">
            <li class="tab mr20 tab-active">拖拽、自由、灵活、随意</li>
          </ul>
        </div>
        <!-- 登录 -->
        <div class="login_form">
          <div v-if="active == 'login'">
            <el-form :model="loginForm" ref="login" label-width="0">
              <el-form-item prop="username" class="item" :verify="{ minLen: 1, maxLen: 16, typeOptions: ['字母|数字'] }">
                <el-input v-model="loginForm.username" placeholder="请输入账户"></el-input>
              </el-form-item>
              <el-form-item prop="password" class="item" :verify="{ passwordOptions: [1, 18, '字母|数字'] }">
                <el-input v-model="loginForm.password" placeholder="请输入密码" show-password></el-input>
              </el-form-item>
            </el-form>
            <el-button class="submit" type="primary" @click="login">登录</el-button>
            <div class="tips">
              <div>
                <div class="text">还没有账号？</div>
                <div class="register" @click="$router.push('/register')">立即注册</div>
              </div>
              <!-- <div class="pass">忘记密码？</div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright">
      <div class="flex row-center">
        <div class="flex row-center">
          <span>运营企业管理平台</span>
        </div>
        <div class="flex row-center ml20">
          <span>Copyright © 2022 运营管理</span>
          <span class="ml20" @click="go">研发技术支持：济文研发中心</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { login, register } from '@/api/user'
import http from '@/utils/cloud'

export default {
  name: 'login',

  created() {
    // 默认给超级管理员
    localStorage.setItem('developers', '1')
  },

  data() {
    return {
      active: 'login',
      loginForm: {},
      registerForm: {}
    }
  },

  watch: {
    active: {
      handler() {
        this.loginForm = {}
        this.registerForm = {}
      }
    }
  },

  methods: {
    ...mapMutations(['setToken', 'setUserInfo']),
    go() {
      window.location.href = 'http://www.jiwenkeji.com/'
    },
    // 登录
    async login() {
      this.$refs['login'].validate(async valid => {
        if (valid) {
          let res = await http.POST('/jw-admin', {
            module: 'admin',
            operation: 'login',
            ...this.loginForm
          })
          if (res.code == 0) {
            this.$notify({
              title: '登陆成功',
              message: '快去体验可视化给项目吧！',
              type: 'success'
            })
            localStorage.setItem('token', res.token)
            localStorage.setItem('uid', res.uid)
            localStorage.setItem('userInfo', res)
            this.setToken(res.token)
            this.setUserInfo(res)
            this.$router.push({ name: 'choice' })
          } else {
            this.$notify({
              title: '登录失败',
              message: res.msg,
              type: 'warning'
            })
          }
        }
      })
    },

    // 注册
    register() {
      this.$refs['register'].validate(async valid => {
        if (valid) {
          let res = await register(this.registerForm)

          if (res.status == '10000') {
            this.$notify({
              title: '注册成功',
              message: '账户已注册成功，快去登录使用吧',
              type: 'success'
            })
            this.active = 'login'
            this.$refs['login'].resetFields()
            setTimeout(() => {
              this.$refs['login'].resetFields()
            }, 0)
          } else {
            this.$notify({
              title: '注册失败',
              type: 'warning',
              message: res.message
            })
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-input__inner {
  width: 100%;
  height: 44px;
  border-radius: 23px;
  border-color: #f4f4f4;
  padding: 0 30px 0 50px;
}

::v-deep .el-input__icon {
  font-size: 20px;
}

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: url('../assets/image/login-bg.png') no-repeat;
  background-size: cover;
  .copyright {
    display: flex;
    position: absolute;
    bottom: 20px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #999999;
  }
  .login-box {
    width: 1200px;
    height: 562px;
    background-color: #ffffff;
    font-size: 0px;
    border-radius: 30px;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 6%);
    display: flex;
    .left {
      width: 600px;
      height: 562px;
      display: flex;
      justify-content: center;
      align-items: center;
      > img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    .right {
      width: 600px;
      height: 100%;
      background: #fff;
      border-radius: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .logo {
        text-align: center;
        img {
          border: none;
          width: 250px;
          height: 100px;
        }
      }
      .hope {
        text-align: center;
        font-size: 0px;
        margin-top: 25px;
        span {
          font-size: 16px;
          font-weight: 400;
          color: #203775;
          display: inline-block;
          font-family: PingFangSC-Regular, PingFang SC;
        }
      }
      .login_title {
        margin-bottom: 25px;
        margin-top: 30px;
        .tab {
          font-size: 20px;
          color: $color-grey;
          cursor: pointer;
        }
        .tab-active {
          font-size: 14px;
          font-weight: 400;
          color: #203775;
          font-family: PingFangSC-Regular, PingFang SC;
        }
        .col-bottom {
          display: flex;
          justify-content: center;
        }
      }
      .login_form {
        width: 380px;
        margin: 0 auto;
        .item {
          &:nth-child(1) {
            ::v-deep .el-input__inner {
              background: url('../assets/image/user.png') 10px center no-repeat #f4f4f4;
            }
          }
          &:nth-child(2) {
            ::v-deep .el-input__inner {
              background: url('../assets/image/password.png') 10px center no-repeat #f4f4f4;
            }
          }
        }
        .submit {
          width: 100%;
          height: 44px;
          text-align: center;
          font-size: 18px;
          border-radius: 22px;
          margin-top: 10px;
        }
        .tips {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 15px;
          > div {
            display: flex;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #999999;
            .text {
              font-size: 14px;
            }
            .register {
              color: #07bb8a;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>
